import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const GlanceStyle = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fbfbfb;
  border-bottom: 1px solid gainsboro;
  padding: 4rem 0;

  h1 {
    font-style: normal;
    font-weight: 300;
    font-size: 52px;
    line-height: 58px;
    text-align: center;
    word-wrap: break-word;
  }

  .bold {
    font-style: normal;
    font-weight: 500;
    font-size: 52px;
    line-height: 58px;
    text-align: center;
  }

  .buttons {
    display: flex;
    flex-direction: row;
  }
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    width: 210px;
    height: 60px;
  }

  .full {
    background: #007de6;
    box-shadow: 0px 4px 16px #d6dbe3;
    border-radius: 60px;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: #ffffff;
    margin-right: 1rem;
    cursor: pointer;
    :hover {
      background-color: #00aeff;
    }
  }

  .empty {
    border: 2px solid #007de6;
    box-sizing: border-box;
    border-radius: 60px;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    color: #007de6;
    :hover {
      color: #00aeff;
      border: 2px solid #00aeff;
    }
  }

  @media (max-width: 768px) {
    h1 {
      font-style: normal;
      font-weight: 500;
      font-size: 40px;
      line-height: 45px;
      text-align: center;
      color: #111111;
    }

    .empty,
    .full {
      font-weight: 500;
      font-size: 20px;
      line-height: 27px;
      text-align: center;
    }
  }

  @media (max-width: 480px) {
    h1 {
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 36px;
      text-align: center;
      color: #111111;
      display: flex;
      flex-direction: column;
      margin-bottom: 3rem;
    }

    .empty,
    .full {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      display: flex;
      align-items: center;
      text-align: center;
    }

    .buttons {
      display: flex;
      flex-direction: column;
    }

    .full {
      margin-bottom: 1rem;
    }
  }
`

const Glance = props => {
  return (
    <div>
      <GlanceStyle>
        <h1>
          <span className="bold">PDF Pro: </span> Features at Glance
        </h1>
        <div className="buttons">
          <Link to="#" className="full" role="popup" onClick={props.open}>
            Start Free Trial
          </Link>

          <Link
            className="empty"
            to={`${props?.location ? props?.location : ""}/checkout`}
          >
            Pricing
          </Link>
        </div>
      </GlanceStyle>
    </div>
  )
}

export default Glance
